import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MuiBox, MuiContainer, MuiTypography, MuiTextField } from 'theme/material-ui';
import { Alert } from 'components/Alert';
import SpinnerModal from 'components/UI/Spinner/SpinnerModal';
import ValidationDialog from 'screens/Profile/Validation';
import { useFormik } from 'formik';
import { FontSize } from 'modules/styles/variables';
import { Color } from 'modules/styles/colors';
import { isValidEmail } from 'modules/utils/EmailUtils';
import { SendPinProps, ValidateResultsState } from 'store/profile/types';
import { ProfileUpdateMessages } from 'store/profile/constants';
import {
  profileValidationIsFetchingSelector,
  sendOTPIsFetchingSelector
} from 'store/profile/selectors';
import StyledButton from 'components/UI/Button/StyledButton';
import { StyledEditContactDiv, ContactCancelButton, ContactHeader } from '../styled';
import { useHistory } from 'react-router';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';

interface FormikValues {
  email: string;
}

export interface EditEmailScreenProps {
  resetValidation?: () => void;
  sendError?: boolean;
  validated?: ValidateResultsState;
  onSubmit: (pin: string, emailToSubmit: string) => Promise<any>;
  sendPin: (data: SendPinProps) => Promise<any>;
  handleCancel: () => void;
  isLoading: boolean;
  initialEmail: string;
}

export default function EditEmailComponent({
  resetValidation,
  sendError,
  validated,
  onSubmit,
  sendPin,
  handleCancel,
  isLoading,
  initialEmail
}: EditEmailScreenProps) {
  const history = useHistory();
  const isFetching = useSelector(profileValidationIsFetchingSelector);
  const otpIsFetching = useSelector(sendOTPIsFetchingSelector);

  const [showValidation, setShowValidation] = useState(false);
  const [submissionValues, setSubmissionValues] = useState<string>(initialEmail);
  const initialValues: FormikValues = {
    email: initialEmail
  };

  const onValidate = (values: FormikValues) => {
    return !isValidEmail(values.email) ? { email: 'Please enter a valid email' } : {};
  };

  const showErrorAlert = (errorCode?: string) => {
    const isBadActor = errorCode?.startsWith('BA');
    const actions = [];
    if (isBadActor) {
      actions.push(
        ...[
          {
            text: 'Contact Support',
            onPress: () => resetAndClose(true, errorCode),
            primary: false
          },
          {
            text: 'Close',
            onPress: () => resetAndClose(false, errorCode)
          }
        ]
      );
    } else {
      actions.push({
        text: 'OK',
        onPress: () => resetAndClose(false, errorCode)
      });
    }

    const message = errorCode
      ? ProfileUpdateMessages.UPDATE_EMAIL_OR_PHONE_BAD_ACTOR.message
      : 'There was an issue sending your pin.';

    const description = isBadActor ? (
      <div>
        <span>
          Please{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              resetAndClose(true, errorCode);
            }}
          >
            contact support
          </span>{' '}
          for assistance.
        </span>
        <div>
          <small>Error {errorCode}</small>
        </div>
      </div>
    ) : (
      ProfileUpdateMessages.VALIDATE_EMAIL.message
    );

    Alert.alert(message, description, actions, {
      buttonAlignment: 'column'
    });
  };

  const validateAndSubmit = async (values: FormikValues) => {
    const { email } = values;
    if (email && email !== initialValues.email) {
      setSubmissionValues(email);
      const res = await sendPin({ type: 'email', email });
      if (!res.error && res?.payload?.data?.success !== false) {
        setShowValidation(true);
      } else {
        const errorCode = res?.payload?.data?.error?.errorCode;
        if (errorCode) {
          analyticsService.logEvent(AnalyticsEvent.BlacklistedUserFailedOtp, { errorCode });
        }
        showErrorAlert(errorCode);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: validateAndSubmit,
    validate: onValidate
  });

  const resetAndClose = (gotoSupport?: boolean, errorCode?: string) => {
    if (resetValidation) {
      resetValidation();
    }
    setShowValidation(false);
    setSubmissionValues('');
    if (gotoSupport === true) {
      history.push('/u/help-support/contact');
    }
    Alert.close();
    if (errorCode) {
      formik.setFieldValue('email', initialEmail);
    }
  };

  return (
    <MuiBox mt={6}>
      <SpinnerModal isLoading={isLoading || isFetching || otpIsFetching} />
      <MuiContainer maxWidth="sm">
        {showValidation && (
          <ValidationDialog
            resetAndClose={resetAndClose}
            sendError={!!sendError}
            validated={!!(validated?.results && validated?.results[submissionValues]?.verifyCode)}
            validateAction={(pin: string) => {
              if (submissionValues) {
                return onSubmit(pin, submissionValues);
              }
              return Promise.resolve();
            }}
          >
            <MuiBox textAlign="center" mb={3}>
              <MuiTypography gutterBottom>
                A code has been emailed to the email you provided
              </MuiTypography>
              <MuiTypography>Please enter the code below</MuiTypography>
            </MuiBox>
          </ValidationDialog>
        )}
        <form onSubmit={formik.handleSubmit}>
          <StyledEditContactDiv p={3}>
            <ContactHeader mb={6}>
              <MuiTypography fontWeight={600} fontSize={FontSize.large} color={Color.textLight}>
                Edit Email
              </MuiTypography>
            </ContactHeader>

            <MuiBox mb={3}>
              <MuiTextField
                type="email"
                name="email"
                fullWidth
                placeholder="Enter Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.email}
                error={Boolean(formik.touched?.email && formik.errors?.email)}
                helperText={
                  formik.touched?.email && formik.errors?.email ? formik.errors.email : null
                }
              />
            </MuiBox>

            <MuiBox p={3}>
              <MuiBox mb={1}>
                <ContactCancelButton fullWidth onClick={handleCancel}>
                  Cancel
                </ContactCancelButton>
              </MuiBox>

              <StyledButton
                fullWidth
                disabled={!formik.isValid || formik.values?.email === initialValues?.email}
                type="submit"
                onClick={formik.handleSubmit}
              >
                Submit
              </StyledButton>
            </MuiBox>
          </StyledEditContactDiv>
        </form>
      </MuiContainer>
    </MuiBox>
  );
}
