import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import ErrorMessage from 'components/Error/ErrorMessage';
import { FontSize, FontWeight, Spacing } from 'modules/styles/variables';
import Spacer from 'components/UI/Layout/Spacer';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Color } from 'modules/styles/colors';
import {
  registerAlertSelector,
  registerCurrentStepSelector,
  RedirectBrand,
  RegisterAlert,
  getRegisterBrand,
  getRedirectIdx
} from 'store/register';
import { getBrandLinkColor } from 'store/register/utils';
import { Step, stepProgress } from 'store/pageActions/RegisterAccount/constants';
import { Steps } from 'store/pageActions/RegisterAccount/types';
import { RootState } from 'store/types';
import { dfdDefaultTheme, MuiBox, MuiContainer, MuiTypography } from 'theme/material-ui';
import SmallText from 'components/UI/Typography/SmallText';
import TypographyInlineWrapper from 'components/UI/Typography/TypographyInLineWrapper';
import FlexBox, { FlexBoxRow } from 'components/UI/Layout/FlexBox';
import { technicalHelpNumber } from 'modules/constants/phoneNumbers';
import { DISCLAIMER } from 'components/UI/Footer/constants';
import { ProgressStepper } from './ProgressStepper';
import RegisterAccount from './screens/RegisterAccount';
import RegisterError from './screens/RegisterError';
import RegisterInsurance from './screens/RegisterInsurance';
import RegisterPersonal from './screens/RegisterPersonal';
import RegisterPersonalAbridged from './screens/RegisterPersonalAbridged';
import RegisterSuccess from './screens/RegisterSuccess';
import RegisterVerifyEmail from './screens/RegisterVerifyEmail';
import RegisterVerifyPhone from './screens/RegisterVerifyPhone';
import StepRoute from './StepRoute';
import {
  ProgressContainer,
  RegistrationContainer,
  RegistrationStepperContainer,
  RegistrationStepper,
  RegistrationHeaderContainer,
  FooterText,
  RegistrationMainContainer,
  RegistrationHeaderLogo,
  RegistrationFooterLogo
} from './styled';
import useStyles from './useStyles';
import analyticsService, { AmplitudeEventData, AnalyticsEvent } from 'services/AnalyticsService';
import LinkingServices from 'services/LinkingServices';
import RegisterHeader from './screens/RegisterHeader';
import RegisterPersonalReview from './screens/RegisterPersonalReview';
import {
  IntermountainHealthLogoSVG,
  SelectHealthSVG,
  SelectHealthCoBrandSVG,
  ScripiusSVG
} from 'components/common/AppHeader/AppHeader';

interface Props {
  alert: RegisterAlert;
  step: Step;
  history: History & { location: { pathname: string } };
}

const baseUrl = '/register';

const getPath = (key: keyof Steps) => `${baseUrl}/${Step[key]}`;

const BrandLogo = ({ redirectBrand }: { redirectBrand: RedirectBrand }) => {
  // note: IH wants to have Select Health's logo but Select Health doesn't want IH's
  return (
    <>
      {redirectBrand === RedirectBrand.SH ? (
        <SelectHealthSVG />
      ) : redirectBrand === RedirectBrand.SC ? (
        <ScripiusSVG />
      ) : (
        <FlexBoxRow
          alignItems="center"
          justifyContent="space-between"
          style={{
            width: `${dfdDefaultTheme.breakpoints.values.sm - Spacing.xLargeLarge}px`
          }}
        >
          <IntermountainHealthLogoSVG />
          <SelectHealthCoBrandSVG />
        </FlexBoxRow>
      )}
    </>
  );
};

const BrandSmallLogo = ({ redirectBrand }: { redirectBrand: RedirectBrand }) => {
  return (
    <MuiBox textAlign="center">
      {redirectBrand === RedirectBrand.SH ? (
        <SelectHealthSVG />
      ) : redirectBrand === RedirectBrand.SC ? (
        <ScripiusSVG />
      ) : (
        <FlexBoxRow
          center
          style={{
            gap: Spacing.xLarge
          }}
        >
          <IntermountainHealthLogoSVG />
          <SelectHealthCoBrandSVG />
        </FlexBoxRow>
      )}
    </MuiBox>
  );
};

const getHeaderColor = (redirectBrand: RedirectBrand): string => {
  switch (redirectBrand) {
    case RedirectBrand.SH:
      return Color.primary;
    case RedirectBrand.SC:
      return Color.scripiusBlue;
    default:
      return Color.baseColor;
  }
};

const getFooterColor = (redirectBrand: RedirectBrand): string => {
  switch (redirectBrand) {
    case RedirectBrand.SH:
      return Color.grayLight9;
    default:
      return Color.baseColor;
  }
};

const Register = ({ step, alert, history }: Props) => {
  const classes = useStyles();

  const redirect_index = getRedirectIdx();
  const redirectBrand = getRegisterBrand();
  const brandLinkColor = getBrandLinkColor({ redirectBrand });
  const headerBg = getHeaderColor(redirectBrand);
  const footerBg = getFooterColor(redirectBrand);
  const handleAnalytics = () => {
    const eventData: AmplitudeEventData = {
      redirect_index,
      redirectBrand
    };
    analyticsService.logEvent(AnalyticsEvent.SupportCalled, eventData);
  };

  return (
    <>
      <RegistrationContainer>
        <RegistrationHeaderContainer>
          <RegistrationHeaderLogo
            flex={1}
            justifyContent="center"
            flexDirection="row"
            alignItems="flex-end"
            padding="16px 0 16px 0"
            maxHeight="40"
            backgroundColor={headerBg}
          >
            <BrandLogo redirectBrand={redirectBrand} />
          </RegistrationHeaderLogo>
          <RegistrationStepperContainer>
            <RegistrationStepper>
              <ProgressContainer>
                <Box className={classes.progressStepper}>
                  <ProgressStepper step={stepProgress[step]} />
                </Box>
              </ProgressContainer>
            </RegistrationStepper>
            <RegisterHeader />
            <Container maxWidth="sm">
              {history.location.pathname === getPath('VERIFY_EMAIL') ? (
                <MuiBox pt={0.5} pb={0.5} px={0} maxWidth={430}>
                  <MuiTypography variant="h5" align="left">
                    You’re doing great!
                  </MuiTypography>
                </MuiBox>
              ) : null}
              <RegistrationMainContainer>
                {!!alert.title && (
                  <>
                    <ErrorMessage alert={alert} />
                    <Spacer spacing="xLarge" />
                  </>
                )}
                <Switch>
                  <Route path={baseUrl} exact>
                    <Redirect to={getPath('INSURANCE')} />
                  </Route>
                  <StepRoute path={getPath('INSURANCE')}>
                    <RegisterInsurance />
                  </StepRoute>
                  <StepRoute path={getPath('PERSONAL')}>
                    <RegisterPersonal />
                  </StepRoute>
                  <StepRoute path={getPath('PERSONAL_REVIEW')}>
                    <RegisterPersonalReview />
                  </StepRoute>
                  <StepRoute path={getPath('PERSONAL_ABRIDGED')}>
                    <RegisterPersonalAbridged />
                  </StepRoute>
                  <StepRoute path={getPath('VERIFY_EMAIL')}>
                    <RegisterVerifyEmail />
                  </StepRoute>
                  <StepRoute path={getPath('VERIFY_PHONE')}>
                    <RegisterVerifyPhone />
                  </StepRoute>
                  <StepRoute path={getPath('ACCOUNT')}>
                    <RegisterAccount />
                  </StepRoute>
                  <StepRoute path={getPath('SUCCESS')}>
                    <RegisterSuccess />
                  </StepRoute>
                  <StepRoute path={getPath('ERROR')}>
                    <RegisterError />
                  </StepRoute>
                  <Route path="*">
                    <Redirect to={getPath('INSURANCE')} />
                  </Route>
                </Switch>
              </RegistrationMainContainer>
              <RegistrationFooterLogo padding="16px 0 16px 0" maxHeight="40">
                <BrandSmallLogo redirectBrand={redirectBrand} />
              </RegistrationFooterLogo>
            </Container>
          </RegistrationStepperContainer>
        </RegistrationHeaderContainer>
      </RegistrationContainer>
      <FlexBox px={0} py={3} backgroundColor={footerBg} padding="1.75rem 0rem">
        <MuiContainer maxWidth="lg">
          <MuiBox
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            px={1.7}
          >
            <FooterText
              color={Color.black}
              font-size={FontSize.large}
              font-weight={FontWeight.semibold}
            >
              Need Help? If you need assistance please contact our Online Services toll-free at{' '}
              <TypographyInlineWrapper
                color={brandLinkColor}
                onClick={() => {
                  handleAnalytics();
                  LinkingServices.callPhoneNumber(technicalHelpNumber);
                }}
              >
                {technicalHelpNumber}
              </TypographyInlineWrapper>
              {redirectBrand === RedirectBrand.SH ? ' (option 2)' : null}
            </FooterText>
          </MuiBox>
          <MuiBox
            alignItems="center"
            display="flex"
            justifyContent="center"
            textAlign="center"
            px={3}
            pt={2}
          >
            <SmallText
              color={Color.black}
              font-size={FontSize.large}
              font-weight={FontWeight.semibold}
            >
              {DISCLAIMER}
            </SmallText>
          </MuiBox>
        </MuiContainer>
      </FlexBox>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  step: registerCurrentStepSelector(state),
  alert: registerAlertSelector(state)
});

export default connect(mapStateToProps)(Register);
