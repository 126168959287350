import React from 'react';
import { MuiButton, MuiTypography } from 'theme/material-ui';
import myStrengthThumb from 'assets/HealthTools/myStrength-thumb.png';
import crediblemind from 'assets/HealthTools/crediblemind-v2.png';
import { HealthToolCardData } from 'components/HealthToolsCards/HealthToolCard';
import { MentalHealthType } from 'services/AnalyticsService.common';
import Spacer from 'components/UI/Layout/Spacer';
import { FontSize } from 'modules/styles/variables';

const ListItem = ({ children }: { children: JSX.Element | string }) => (
  <li style={{ display: 'list-item', listStyleType: 'disc', listStylePosition: 'inside' }}>
    {children}
  </li>
);

export function getMentalHealthProps(
  mentalHealthVariant: MentalHealthType
): {
  text: JSX.Element | string;
  url: string;
} {
  const text = (
    <div>
      <div style={{ textAlign: 'center', fontSize: FontSize.xsmall, fontWeight: 'bold' }}>
        Take charge of your mental health with resources to help you:
      </div>
      <Spacer size="small" />
      <div style={{ fontSize: FontSize.xsmall, fontWeight: 'bold' }}>
        <div style={{ width: '49%', display: 'inline-block' }}>
          <ul>
            <ListItem>Relieve stress</ListItem>
            <ListItem>Manage anxiety</ListItem>
            <ListItem>Sleep better</ListItem>
          </ul>
        </div>
        <div style={{ width: '49%', display: 'inline-block' }}>
          <ul style={{ listStyleType: '&#9679;' }}>
            <ListItem>Beat burnout</ListItem>
            <ListItem>Prevent depression</ListItem>
            <ListItem>And so much more…</ListItem>
          </ul>
        </div>
      </div>
      <Spacer size="small" />
      <div style={{ textAlign: 'center' }}>
        <MuiButton color="primary" variant="contained" fullWidth>
          Get Started!
        </MuiButton>
      </div>
    </div>
  ) as JSX.Element;
  let url =
    'https://intermountainhealth.crediblemind.com/?utm_source=ut-portal&utm_medium=Int-promo&utm_campaign=portal-primary&utm_content=';
  switch (mentalHealthVariant) {
    case 'b':
      url += 't2v2';
      break;
    default:
    case 'a':
      url += 't2v1';
  }
  return { url, text };
}

export const myStrengthCard = (mentalHealthVariant: MentalHealthType): HealthToolCardData => {
  const { text, url } = getMentalHealthProps(mentalHealthVariant);

  return {
    flexDirection: 'column',
    healthToolCardKey: 'myStrength',
    description: <MuiTypography display="inline">{text}</MuiTypography>,
    path: '/u/health-tools/mystrength',
    thumb: mentalHealthVariant === 'a' ? myStrengthThumb : crediblemind,
    url,
    variant: mentalHealthVariant
  };
};
