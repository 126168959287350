import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Consumer,
  SendPinProps,
  ValidatePinProps,
  ValidateResultsState
} from 'store/profile/types';
import { CenteredSpinner } from 'components/UI/Spinner/SpinnerContainer';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { RootState } from 'store/types';
import * as profileSelectors from 'store/profile/selectors';
import { MuiBox, MuiButton, MuiTypography } from 'theme/material-ui';
import Spacer from 'components/UI/Layout/Spacer';
import { FontSize } from 'modules/styles/variables';
import { oc } from 'ts-optchain';
import { resetValidationState, sendIDWPin, sendIDWVerification } from 'store/profile/actions';
import ValidationDialog from '../Validation';
import { Alert } from 'components/Alert';
import { ProfileUpdateMessages } from 'store/profile/constants';
import { cleanPhone } from 'modules/utils/PhoneUtils';
import { Color } from 'modules/styles/colors';
import { useHistory } from 'react-router';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { isConsumerVerified } from 'store/profile/util';

export interface MyProfileProps {
  consumer: Consumer;
  otpFetching?: boolean;
  validated: ValidateResultsState;
  sendError: boolean;
  resetValidation: () => void;
  sendPinToSelectedOption: (data: SendPinProps) => Promise<any>;
  validatePinByType: (data: ValidatePinProps) => Promise<any>;
}

const MyProfileValidation = ({
  consumer,
  otpFetching,
  validated,
  sendError,
  resetValidation,
  sendPinToSelectedOption,
  validatePinByType
}: MyProfileProps) => {
  const history = useHistory();
  const phone = oc(consumer).phones[0]();
  const email = oc(consumer).emails[0]();
  const phoneNumber = phone && phone.value ? phone.value : '';
  const emailValue = email && email.value ? email.value : '';
  const maskedPhone = phoneNumber ? `(***)***-${phoneNumber?.slice(-4)}` : '';
  const maskedEmail = emailValue ? `${emailValue[0]}***@${emailValue?.split('@')[1]}` : '';
  const [preference, setPreference] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const isValidated = isConsumerVerified(consumer, validated);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPreference(value as 'email' | 'sms');
    setDisabled(!!value);
    setSelectedOption(value === 'email' ? value : 'number');
  };

  const resetAndClose = (gotoSupport?: boolean) => {
    if (resetValidation) {
      resetValidation();
    }
    setShowValidation(false);
    setPreference('');
    setDisabled(false);
    if (gotoSupport === true) {
      history.push('/u/help-support/contact');
    }
    Alert.close();
  };

  const showErrorAlert = (message: string, errorCode?: string) => {
    const isBadActor = errorCode?.startsWith('BA');
    const actions = [];
    if (isBadActor) {
      actions.push(
        ...[
          {
            text: 'Contact Support',
            onPress: () => resetAndClose(true),
            primary: false
          },
          {
            text: 'Close',
            onPress: () => resetAndClose()
          }
        ]
      );
    } else {
      actions.push({
        text: 'Ok',
        onPress: () => resetAndClose()
      });
    }
    const description = isBadActor ? (
      <div>
        <span>
          Please{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              resetAndClose(true);
            }}
          >
            contact support
          </span>{' '}
          for assistance.
        </span>
        <div>
          <small>Error {errorCode}</small>
        </div>
      </div>
    ) : (
      ProfileUpdateMessages.VALIDATE_EMAIL_OR_PHONE.message
    );

    Alert.alert(message, description, actions, {
      buttonAlignment: 'column'
    });
  };

  const handlePinFail = (response: any) => {
    const errorCode = response?.payload?.data?.error?.errorCode;
    const message = errorCode
      ? ProfileUpdateMessages.UPDATE_EMAIL_OR_PHONE_BAD_ACTOR.message
      : 'There was an issue sending your pin.';
    if (errorCode?.startsWith('BA')) {
      analyticsService.logEvent(AnalyticsEvent.BlacklistedUserFailedOtp, { errorCode });
    }
    analyticsService.logEvent(AnalyticsEvent.AccountViewAccessCodeSentError);
    showErrorAlert(message, errorCode);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setDisabled(false);
    event.preventDefault();
    let pinType: 'email' | 'sms' = 'email';
    preference === 'sms' ? (pinType = 'sms') : (pinType = 'email');
    const response = await sendPinToSelectedOption({
      type: pinType,
      phone: phoneNumber,
      email: emailValue
    });
    if (!response.error && response?.payload?.data?.success) {
      analyticsService.logEvent(AnalyticsEvent.AccountViewAccessCodeSent);
      setShowValidation(true);
    } else {
      handlePinFail(response);
    }
  };

  const validateAccessCode = async (type: string, value: string) => {
    let pinType: 'email' | 'sms' = 'email';
    type === 'sms' ? (pinType = 'sms') : (pinType = 'email');
    const numberToSubmit = cleanPhone(phoneNumber);
    const res = await validatePinByType({
      type: pinType,
      phone: numberToSubmit,
      email: emailValue,
      pin: value
    });
    if (!res.error && res?.payload?.data?.success) {
      analyticsService.logEvent(AnalyticsEvent.AccountViewAccessCodeValidated);
      setShowValidation(false);
    } else {
      const errorCode = res?.payload?.data?.error?.errorCode;
      analyticsService.logEvent(AnalyticsEvent.AccountViewAccessCodeValidationFailed);
      showErrorAlert('There was an issue validating your pin.', errorCode);
    }
  };

  const showContactusDialog = () => {
    history.push('/u/help-support/contact');
  };
  return (
    <Container component="main" maxWidth="md">
      {otpFetching ? <CenteredSpinner /> : null}
      {showValidation ? (
        <ValidationDialog
          resetAndClose={resetAndClose}
          sendError={!!sendError}
          validated={isValidated}
          validateAction={pin => {
            return validateAccessCode(preference, pin);
          }}
        >
          <MuiBox textAlign="center" mb={3}>
            <MuiTypography gutterBottom>
              A code has been sent to the {selectedOption} on file
            </MuiTypography>
            <MuiTypography>Please enter the code below</MuiTypography>
          </MuiBox>
        </ValidationDialog>
      ) : null}
      <Box textAlign="center" mb={1}>
        <MuiTypography fontSize={FontSize.small} variant="h6">
          Your account profile contains sensitive information (eg: email, phone number, change
          password, etc.).
        </MuiTypography>
        <Spacer spacing="small" />
        <MuiTypography fontSize={FontSize.small} variant="h6" paragraph>
          In order to view or update, please select your preferred method to receive a temporary
          one-time access code.
        </MuiTypography>
      </Box>
      <Box textAlign="center" mb={1}>
        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset" margin="dense">
            <RadioGroup
              aria-label="preference"
              name="preference"
              value={preference}
              onChange={handleChange}
            >
              <FormControlLabel
                value="email"
                control={<Radio />}
                label={`Email address: ${maskedEmail}`}
              />
              <FormControlLabel
                value="sms"
                control={<Radio />}
                label={`Text message: ${maskedPhone}`}
              />
            </RadioGroup>
          </FormControl>

          <Box mt={3}>
            <MuiButton
              disabled={!disabled}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Send access code
            </MuiButton>
          </Box>
          <Spacer spacing="medium" />
          <Box textAlign="center" mb={2}>
            <MuiTypography variant="body2">
              Need assistance?{' '}
              <MuiTypography
                color={Color.link}
                display="inline"
                onClick={() => showContactusDialog()}
                style={{ cursor: 'pointer' }}
              >
                Contact Us
              </MuiTypography>
            </MuiTypography>
          </Box>
        </form>
      </Box>
    </Container>
  );
};
const mapDispatch = (dispatch: Function) => ({
  sendPinToSelectedOption: async ({ type, phone, email }: SendPinProps) => {
    const value = type === 'sms' && phone ? phone : type === 'email' && email;
    if (!value) {
      return { payload: { data: { success: false } } };
    }
    return dispatch(sendIDWPin(type === 'sms' ? 'phone' : 'email', value));
  },
  resetValidation: () => dispatch(resetValidationState()),
  validatePinByType: (data: ValidatePinProps) => {
    let type: 'email' | 'phone';
    let value: string;
    if (data.type === 'sms' && data.phone) {
      type = 'phone';
      value = data.phone;
    } else if (data.type === 'email' && data.email) {
      type = 'email';
      value = data.email;
    } else {
      return { payload: { data: { success: false } } };
    }
    return dispatch(sendIDWVerification(type, value, data.pin));
  }
});

const mapState = (state: RootState) => ({
  consumer: profileSelectors.profileConsumerSelector(state),
  otpFetching: profileSelectors.sendOTPIsFetchingSelector(state),
  validated: profileSelectors.profileValidatedSelector(state),
  sendError: profileSelectors.profileValidationSendErrorSelector(state)
});

export default connect(mapState, mapDispatch)(MyProfileValidation);
