import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setFAFormClear } from 'store/billing/financialAssistance/createApplication/actions';
import { useHistory, useLocation } from 'react-router';
import RouteLeavingGuard from 'components/UI/Modals/RouteLeavingGuardModal';
import { exitModalText } from 'modules/constants/exitConfirmModal';
import { SHARE_FEEDBACK_SCENARIOS } from 'lib/constants/help';

export function FAExitGuard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isRouteGuardOpen, setIsRouteGuardOpen] = React.useState(false);

  const modelText = exitModalText('Financial Assistance');
  const allowedPaths = [
    '/u/fa-app/basic-info',
    '/u/fa-app/household',
    '/u/fa-app/assets',
    '/u/fa-app/other-funding',
    '/u/fa-app/bills',
    '/u/fa-app/complete'
  ];

  useEffect(() => {
    setIsRouteGuardOpen(true);
  }, [location]);

  const handleNavigate = (path?: string) => {
    dispatch(setFAFormClear());
    setIsRouteGuardOpen(false);
    if (path !== '/u/help-support/share-feedback') {
      history.push(path || '/u/fa-app');
    }
  };

  const exitAndShareFeedback = (path?: string) => {
    dispatch(setFAFormClear());
    setTimeout(() => {
      history.push('/u/help-support/share-feedback', {
        scenario: SHARE_FEEDBACK_SCENARIOS.financialAssistance,
        screenLocation: '/u/fa-app',
        navigateTo: path
      });
    }, 1); // Note stick this on the end of the stack.
  };

  return (
    <RouteLeavingGuard
      when={isRouteGuardOpen}
      shouldBlockNavigation={nextLocation => {
        return !allowedPaths.includes(nextLocation.pathname);
      }}
      exitTitle={modelText.title}
      exitSubtitle={modelText.subtitle}
      exitButtonText={modelText.primaryButton}
      keepGoingButtonText={modelText.secondaryButton}
      exitAndShareButtonText={modelText.textButton}
      navigate={handleNavigate}
      exitAndShareHandler={exitAndShareFeedback}
    />
  );
}
