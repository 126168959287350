import React from 'react';
import styled from 'styled-components';
import { Color } from 'modules/styles/colors';
import { MuiBox, MuiLinearProgress, dfdDefaultTheme } from 'theme/material-ui';
import { RedirectBrand, getRegisterBrand } from 'store/register';

const getBarProgressColor = (): string => {
  const redirectBrand = getRegisterBrand();
  switch (redirectBrand) {
    case RedirectBrand.SH:
    case RedirectBrand.SC:
      return Color.salmon;
    default:
      return Color.secondary;
  }
};

const getBarBaseColor = (): string => {
  const redirectBrand = getRegisterBrand();
  switch (redirectBrand) {
    case RedirectBrand.SH:
      return Color.grayLight10;
    default:
      return Color.grayLight;
  }
};

const StyledLinearProgress = styled(MuiLinearProgress)`
  && {
    & .MuiLinearProgress-barColorPrimary {
      background-color: ${() => {
        return getBarProgressColor();
      }};
    }
    background-color: ${() => getBarBaseColor()};
    height: 14px;
    ${dfdDefaultTheme.breakpoints.between('xs', 'sm')} {
      height: 5px;
    }
  }
`;

export const ProgressStepper = ({ step }: { step: number }) => {
  const progress = Math.ceil((step / 5) * 100);
  return (
    <MuiBox>
      <StyledLinearProgress variant="determinate" value={progress} />
    </MuiBox>
  );
};
